import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
          
            </div>
          </div>
        </div>

        <div className="row why-choose-us ">

        <div className="col-xs-12 col-md-6 ">
            <div className="about-text">
              
              <h3>Why Choose Us?</h3>
                <p>
                   We specialize in engineering scalable applications that are API first with responsive user interfaces. Leadership team has 25+ years of experience in engineering solutions for complex issues and project management.
                   We can be your tech-partner in delivering end-to-end software solutions from requirements analysis to architecture to development of software and deployment to production environment.
                   Our DevOps engineers & managed services team can take care of your application monitoring / up-time 24x7x365.
                </p>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6  ">
            {" "}
            <img src="img/why5.jpg" className="img-responsive" alt="" />{" "}
          </div>
      
        </div>
      </div>
    </div>
  );
};
