import {React,useEffect,useState} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 


const initialState = {
  name: "",
  email: "",
  message: "",
  service:"",
   file: null,
};

export const Contact = (props) => {
 
  const { selectedCategory } = props;

  const [{ name, email, service, message, file }, setState] = useState(initialState);
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");
  useEffect(() => {
    if (selectedCategory) {
      setState((prevState) => ({ ...prevState, service: selectedCategory }));
    }
  }, [selectedCategory]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=6LeY4WIqAAAAAN1JezQOb_jWiUnlr-BIgimgnMGq";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  

  useEffect(() => {
    AOS.init({
      duration: 1000, 
      once: false, 
    })
    AOS.refresh();
  }, []);
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "file") {
      const selectedFile = files[0];

      if (selectedFile.type !== "application/pdf") {
        setFileError("Only PDF files are allowed.");
        setFileName("");
        setState((prevState) => ({ ...prevState, file: null }));
        return;
      }

      const maxSize = 2 * 1024 * 1024; 
      if (selectedFile.size > maxSize) {
        setFileError("File size must be less than 2MB.");
        setFileName("");
        setState((prevState) => ({ ...prevState, file: null }));
        return;
      }

      setFileError("");
      setFileName(selectedFile.name);
      setState((prevState) => ({ ...prevState, file: selectedFile }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const clearState = () => {
    setState({ ...initialState });
    setFileName("");
    setFileError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const token = await window.grecaptcha.execute('6LeY4WIqAAAAAN1JezQOb_jWiUnlr-BIgimgnMGq', { action: 'submit' });

      sendEmail(token);
    } catch (error) {
      console.error("reCAPTCHA execution failed:", error);
      toast.error("Error verifying reCAPTCHA. Please try again.");
    }
  };

  const sendEmail = async (token) => {
    let base64File = null;
  
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      base64File = await new Promise((resolve) => {
        reader.onload = () => resolve(reader.result.split(',')[1]);
      });
    }
  
    const templateParams = {
      name,
      email,
      service,
      message,
      file_name: fileName || null,
      upload_cV: base64File || null,
      recaptchaToken: token,
    };
  
    try {
      const response = await emailjs.send(
        "service_e4evmxq",
        "template_tfakzy6",
        templateParams,
        "cQOaw6pWivAI9WWd9"
      );
      console.log("Email sent successfully:", response);
      toast.success("Email sent successfully!");
      clearState();
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Error sending email. Please try again.");
    }
  };
  
  

  return (
    <div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
      <div id="contact">
        <div className="container">
          <div className="col-md-5 get-in-touch" data-aos="zoom-in-right">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form
                name="sentMessage"
                validate
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <div className="form-group ">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    required
                    onChange={handleChange}
                    value={name}
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    required
                    onChange={handleChange}
                    value={email}
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
  <select
    id="service"
    name="service"
    className="form-control service"
    required  
    onChange={handleChange}
    value={service}
  >
        <option value="">Pick a Service</option>
                    <option value="Contact us for a free consultation">Free Consultation</option>
                    <option value="Contact us to discuss your software and cloud needs">Software and Cloud Needs</option>
                    <option value="Schedule a free DevOps & CI/CD strategy session">DevOps & CI/CD</option>
                    <option value="Contact us to learn more about Maintenance As Software Service and how it can simplify your maintenance">
                      Software Maintenance
                    </option>
  </select>
  <p className="help-block text-danger"></p>
</div>

                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                    value={message}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <label htmlFor="file" className="custom-file-upload">
                    Upload CV{" "}
                    <span className="upload-icon">
                      <i className="fa fa-upload"></i>
                    </span>
                    
                    {fileName && <span className="file-name"> {fileName}</span>}
                  </label>
                  <input
                    type="file"
                    id="file"
                    name="file"
                    className="form-control"
                    style={{ display: "none" }}
                    accept=".pdf"
                    onChange={handleChange}
                  />
                  {fileError && <p className="text-danger fileError">{fileError}</p>}
                  <p className="help-block text-danger"></p>

                </div>

                <div id="success"></div>
                <button  type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>


          <div className="col-md-5 col-md-offset-2 contact-info" data-aos="zoom-in-left">
            <h3>CONTACT INFO</h3>
            <div className="contact-item office">

              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Head Office
                </span>
                {props.data ? props.data.address.map((line, index) => (
                  <div key={index}>{line}</div>
                ))
                  : "loading"}
              </p>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Branch Office
                </span>
                {props.data ? props.data.branchAddress.map((line, index) => (
                  <div key={index}>{line}</div>
                ))
                  : "loading"}
              </p>
            </div>
            <div className="contact-item  ">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  {props.data ? props.data.phone : "loading"}
                </p>
           
             
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </p>
              </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Cloud Ripples Pvt Ltd{" "}
            <a href="http://www.cloudripples.io" rel="nofollow">
              Cloud Ripples
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
