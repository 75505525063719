import {React,useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
export const Technology = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
      once: false, 
    })
    AOS.refresh();
  }, []);
  return (
    <div id="technology" >
      <div className="container">
        <div className="section-title text-center">
          <h2>Technologies</h2>
        </div>
        {/* <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3">
                  <div className="partners">
                    { <div className="partners-image">
                      {" "}
                      <img src={d.img} alt="" />{" "}
                    </div> }
                  </div>
                </div>
              ))
            : "loading"}
        </div> */}
<div className="row" >
  {props.data
    ? props.data.map((d, i) => (
        <div key={`${d.name}-${i}`} className={`col-md-2 cl-logo-${i}`}>
          <div className="technology">
            <div className={`technology-image class-${i}`} data-aos="fade-down"
     data-aos-anchor-placement="bottom-bottom">
              <img src={d.img} alt={d.name} />
            </div>
          </div>
        </div>
      ))
    : "loading"}
</div>

        {/* <div class="row">
            <div class="col-6 col-md-4 col-lg-3 text-center">
                <img src="img/cr-logo.png" alt="Client Logo 1" class="client-logo" />
            </div>
            <div class="col-6 col-md-4 col-lg-3 text-center">
                <img src="img/cr-logo.png" alt="Client Logo 1" class="client-logo" />
            </div>
            <div class="col-6 col-md-4 col-lg-3 text-center">
                <img src="img/cr-logo.png" alt="Client Logo 1" class="client-logo" />
            </div>
            <div class="col-6 col-md-4 col-lg-3 text-center">
                <img src="img/cr-logo.png" alt="Client Logo 1" class="client-logo" />
            </div>
            <div class="col-6 col-md-4 col-lg-3 text-center">
                <img src="img/cr-logo.png" alt="Client Logo 1" class="client-logo" />
            </div>                                    
        </div> */}
      </div>
    </div>
  );
};
